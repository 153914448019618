import React, { useState } from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import Footer from '../Footer'
import { navObj } from '../Navbar/data';
import { footerObj } from '../Footer/data';
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import seoData from "./seoData";

const Layout = ({children}) => {
  const [isopen, setIsOpen] = useState(0);
  const location = useLocation();

  const toggle = () => {
    setIsOpen(isopen === 1 ? 0 : 1);
  };



  const seo = seoData[location.pathname] || seoData["/"];

  return (
    <>
      <Helmet key={location.pathname}>
        {/* Standard Meta Tags */}
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <link rel="canonical" href={seo.canonical} />

        {/* Open Graph Tags (For Social Media) */}
        <meta property="og:title" content={seo.ogTitle} />
        <meta property="og:description" content={seo.ogDescription} />
        <meta property="og:url" content={seo.ogUrl} />
        <meta property="og:image" content={seo.ogImage} />
        <meta property="og:image:secure" content={seo.ogImage} />
        <meta property="og:type" content="website" />

        {/* Twitter Card (Optional but Recommended) */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo.ogTitle} />
        <meta name="twitter:description" content={seo.ogDescription} />
        <meta name="twitter:image" content={seo.ogImage} />
      </Helmet>
      <Sidebar isopen={isopen} toggle={toggle}/>
      <Navbar toggle={toggle} {...navObj}/>
        <main>{children}</main>
      <Footer {...footerObj}/>
    </>
  )
}

export default Layout