import React from 'react'
import Layout from '../../components/Layout';
import HeroPage from './Hero';
import Solutions from './Solutions';
import AboutUs from './About';
import FinancialIncentives from './Incentives';
import WhyLunex from './WhyLunex';
import Ethics from './Ethics';
import Advantage from './Advantage';
import Portfolio from './Portfolio';
import ContactUs from './Contact';
import styled from 'styled-components';

const Container = styled.div`
    background-color: #fff;
`

const Commercial = () => {
  return (
    <>
    <Layout>
      <Container>
          <HeroPage />
          <AboutUs />
          <Solutions />
          <FinancialIncentives />
          <WhyLunex />
          <Ethics />
          <Portfolio />
          <Advantage />
          <ContactUs />
      </Container>
    </Layout>
    </>
  )
}

export default Commercial