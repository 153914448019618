import styled, { css } from "styled-components";
import { newFadeIn, slideDown, slideInFromRight } from "../../animations.js";
import lunexPowerLogo from "../../images/LunexPowerLogoPlain.png";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const StateHeroWrapper = styled.section`
  font-family: "Avenir_Book";
  box-sizing: border-box;
  position: relative;
  background-color: black;
  ${({ $heroImage }) =>
    $heroImage &&
    css`
      background-image: url(${$heroImage});
      background-size: cover;
      background-attachment: fixed;
    `}
  ${({ $backgroundPosition }) =>
    $backgroundPosition
      ? css`
          background-position: ${$backgroundPosition};
        `
      : css`
          background-position: center;
        `}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh + 80px);
  margin-top: -80px;
  z-index: 6;

  @media (max-width: 64em) {
    //1024px
    background-attachment: scroll;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 7;
  }
`;

const BackLink = styled(Link)`
  position: absolute;
  top: 100px;
  left: 20px;
  font-size: 1rem;
  color: white;
  border-radius: 50px;
  background-color: #ffa500;
  /* text-transform: uppercase; */
  text-decoration: none;
  padding: 0.8rem 1.4rem;
  transition: all 0.1s;
  z-index: 8;
  animation: ${newFadeIn} 2s ease forwards;

  @media (max-width: 37.5em) {
    //600px
    font-size: 0.8rem;
    padding: 0.6rem 1rem;
  }

  &:hover {
    cursor: pointer;
    /* text-decoration: underline; */
    background-color: white;
    color: #ffa500;
  }
`;

const StateTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 12;
`;

const StateTitleLogo = styled.img`
  aspect-ratio: 3.6496;
  width: 34rem;
  margin-right: 1rem;
  animation: ${slideDown} 2s ease forwards;

  @media (max-width: 37.5em) {
    //600px
    max-width: 90vw;
    margin: 0;
  }
`;

const StateIconWrapper = styled.div`
  animation: ${newFadeIn} 2s ease forwards;
`;

const StateHeroTitle = styled.h1`
  display: inline-block;
  font-size: 4rem;
  font-weight: 900;
  font-family: "Avenir_Book";
  color: white;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-bottom: 1.2rem;
  opacity: 0;
  color: #ffa500;
  transform: translateX(100px);
  animation: ${slideInFromRight} 2s ease forwards;

  @media (max-width: 37.5em) {
    //600px
    font-size: 3rem;
  }

  @media (max-width: 28.125em) {
    //450px
    font-size: 2rem;
  }
`;

const StateHero = function ({
  stateName,
  heroIcon,
  heroIcon2,
  heroImage,
  heroBackgroundPosition,
}) {
  const [smallLogo, setSmallLogo] = useState(false);

  // console.log(heroImage);

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= 500 ? setSmallLogo(true) : setSmallLogo(false);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  });

  return (
    <StateHeroWrapper
      $heroImage={heroImage}
      $backgroundPosition={heroBackgroundPosition}
    >
      <BackLink to="/service-areas">&larr; Service Areas</BackLink>
      <StateTitleWrapper>
        <StateTitleLogo src={lunexPowerLogo} alt="Lunex Power Logo" />
        <StateHeroTitle>{stateName}</StateHeroTitle>
        <StateIconWrapper>{smallLogo ? heroIcon2 : heroIcon}</StateIconWrapper>
      </StateTitleWrapper>
    </StateHeroWrapper>
  );
};

export default StateHero;
