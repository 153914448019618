const seoData = {
    "/": {
      title: "Lunex Power: Expert Solar and Battery Installations and Repairs",
      description:
        "Solar panel & Tesla battery solutions for homes/businesses in RI, CT, MA, NH, FL. Based in Tampa, FL. Get a free consultation today.",
      canonical: "https://www.lunexpower.com/",
      ogTitle: "Lunex Power - Go Solar Today!",
      ogDescription:
        "Switch to solar with Lunex Power. Save money, increase energy independence, and get expert installations.",
      ogUrl: "https://www.lunexpower.com/",
      ogImage: "https://www.lunexpower.com/LunexPower.webp",
    },
    "/commercial": {
      title: "Commercial Solar Solutions | Lunex Power",
      description:
        "Lunex Power provides top-tier commercial solar installation services. Learn how your business can benefit from solar energy today.",
      canonical: "https://www.lunexpower.com/commercial",
      ogTitle: "Commercial Solar - Save on Energy Costs",
      ogDescription:
        "Power your business with clean energy! Discover Lunex Power's commercial solar solutions.",
      ogUrl: "https://www.lunexpower.com/commercial",
      ogImage:
        "https://www.lunexpower.com/75kW_Worcester_MA_Canal_Lofts_Ballasted_w_Bifacial_modules.jpg",
    },
    "/faq": {
      title: "Frequently Asked Questions | Lunex Power",
      description:
        "Find answers to common questions about solar and battery solutions with Lunex Power.",
      canonical: "https://www.lunexpower.com/faq",
      ogTitle: "Solar FAQ - Get Your Questions Answered",
      ogDescription:
        "Wondering how solar works? Our FAQ page has answers to all your solar and battery storage questions.",
      ogUrl: "https://www.lunexpower.com/faq",
      ogImage: "https://www.lunexpower.com/LunexPower.webp",
    },
    "/service-areas": {
      title: "Our Service Areas | Lunex Power",
      description:
        "Lunex Power installs solar panels and battery solutions across multiple states. Check if we serve your area.",
      canonical: "https://www.lunexpower.com/service-areas",
      ogTitle: "Where We Install Solar | Lunex Power",
      ogDescription:
        "Lunex Power provides solar solutions in multiple states. Find out if we serve your area!",
      ogUrl: "https://www.lunexpower.com/service-areas",
      ogImage: "https://www.lunexpower.com/LunexPower.webp",
    },
    "/service-areas/florida": {
      title: "Solar Installation in Florida | Lunex Power",
      description:
        "Lunex Power offers solar panel and battery backup installations in Florida. Get your free solar consultation today.",
      canonical: "https://www.lunexpower.com/service-areas/florida",
      ogTitle: "Solar in Florida | Lunex Power",
      ogDescription:
        "Going solar in Florida? Lunex Power provides expert solar installations with top-tier panels and battery storage.",
      ogUrl: "https://www.lunexpower.com/service-areas/florida",
      ogImage: "https://www.lunexpower.com/LunexPower.webp",
    },
    "/service-areas/massachusetts": {
      title: "Solar Installation in Massachusetts | Lunex Power",
      description:
        "Find out how Lunex Power is helping homeowners and businesses in Massachusetts go solar with expert installations.",
      canonical: "https://www.lunexpower.com/service-areas/massachusetts",
      ogTitle: "Solar in Massachusetts | Lunex Power",
      ogDescription:
        "Massachusetts residents, take control of your energy costs! Get a quote for solar panel installation today.",
      ogUrl: "https://www.lunexpower.com/service-areas/massachusetts",
      ogImage: "https://www.lunexpower.com/LunexPower.webp",
    },
    "/service-areas/rhodeisland": {
      title: "Solar Installation in Rhode Island | Lunex Power",
      description:
        "Lunex Power provides top-quality solar panel installations in Rhode Island. Learn how solar can save you money.",
      canonical: "https://www.lunexpower.com/service-areas/rhodeisland",
      ogTitle: "Solar in Rhode Island | Lunex Power",
      ogDescription:
        "Rhode Island homeowners can now save with solar! Explore Lunex Power's expert solar installations.",
      ogUrl: "https://www.lunexpower.com/service-areas/rhodeisland",
      ogImage: "https://www.lunexpower.com/LunexPower.webp",
    },
    "/service-areas/connecticut": {
      title: "Solar Installation in Connecticut | Lunex Power",
      description:
        "Get expert solar installation services in Connecticut with Lunex Power. Start your journey to clean energy today.",
      canonical: "https://www.lunexpower.com/service-areas/connecticut",
      ogTitle: "Solar in Connecticut | Lunex Power",
      ogDescription:
        "Connecticut homeowners, reduce your energy bills with Lunex Power's solar panel solutions.",
      ogUrl: "https://www.lunexpower.com/service-areas/connecticut",
      ogImage: "https://www.lunexpower.com/LunexPower.webp",
    },
    "/service-areas/colorado": {
      title: "Solar Installation in Colorado | Lunex Power",
      description:
        "Lunex Power brings solar energy solutions to Colorado homeowners and businesses. Learn more today.",
      canonical: "https://www.lunexpower.com/service-areas/colorado",
      ogTitle: "Solar in Colorado | Lunex Power",
      ogDescription:
        "Thinking about going solar in Colorado? Get a free quote and expert installation services today.",
      ogUrl: "https://www.lunexpower.com/service-areas/colorado",
      ogImage: "https://www.lunexpower.com/LunexPower.webp",
    },
    "/service-areas/northcarolina": {
      title: "Solar Installation in North Carolina | Lunex Power",
      description:
        "Learn about our solar panel and battery solutions available in North Carolina with Lunex Power.",
      canonical: "https://www.lunexpower.com/service-areas/northcarolina",
      ogTitle: "Solar in North Carolina | Lunex Power",
      ogDescription:
        "North Carolina homeowners can go solar with Lunex Power. Explore your options today.",
      ogUrl: "https://www.lunexpower.com/service-areas/northcarolina",
      ogImage: "https://www.lunexpower.com/LunexPower.webp",
    },
    "/service-areas/southcarolina": {
      title: "Solar Installation in South Carolina | Lunex Power",
      description:
        "Lunex Power helps South Carolina residents transition to clean solar energy. Discover our services today.",
      canonical: "https://www.lunexpower.com/service-areas/southcarolina",
      ogTitle: "Solar in South Carolina | Lunex Power",
      ogDescription:
        "Looking for solar installation in South Carolina? Lunex Power provides expert solutions for homeowners.",
      ogUrl: "https://www.lunexpower.com/service-areas/southcarolina",
      ogImage: "https://www.lunexpower.com/LunexPower.webp",
    },
  };
  
  export default seoData;
  