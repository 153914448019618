// ScopeOfWork.js
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import {
  Typography,
  Button,
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Logo, Title } from "../NewDeal/NewDealElements";
import { Oval } from "react-loading-icons";

const StyledContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-bottom: 20px;
  width: auto;
  max-width: 98%;
`;

function ScopeOfWork({ img, alt }) {
  const { id } = useParams();

  const [data, setData] = useState({
    title: "",
    proposalUrl: "",
    planUrl: "",
    status: "", // "pending", "approved", or "denied"
    denialMessage: "",

    // Deal Details
    customerName: "",
    phoneNumber: "",
    email: "",
    salesRep: "",
    setter: "",
    leadSource: "",

    // System Details
    systemSize: "",
    panelType: "",
    panelCount: "",
    mountingType: "",
    inverterType: "",
    inverterCount: "",

    // Financing Details
    lender: "",
    termLength: "",
    financeType: "",
    interestRate: "",
    totalContractAmount: "",
    dealerFeeAmount: "",

    // Adder Details
    moduleAdders: "",
    batteryAdders: "",
    projectFees: "",
    roofTypeAdders: "",
    mountingAdders: "",
    groundMountAdders: "",
    adder1: "",
    adder2: "",
    adder3: "",
    electricalAdders: "",

    // Commission Breakdown
    grossPPW: "",
    totalAddersPPW: "",
    netPPW: "",
    totalCommission: "",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [showDenialInput, setShowDenialInput] = useState(false);
  const [localDenialMessage, setLocalDenialMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}coperniq/getScopeOfWork?id=${id}`)
      .then((res) => res.json())
      .then((responseData) => {
        setData(responseData);
        setLocalDenialMessage(responseData.denialMessage || "");
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching project data:", err);
        setErrorMessage("Error loading data");
        setIsLoading(false);
      });
  }, [id]);

  // Helper function for commission cell styles
  const getCommissionCellStyle = (label) => {
    if (label === "Gross PPW") return { backgroundColor: "#fff2cc" };
    if (label === "Total Adders (PPW)") return { backgroundColor: "#ea9999" };
    if (label === "Net PPW" || label === "Total Commission")
      return { backgroundColor: "#b6d7a8" };
    return {};
  };

  // Unified endpoint for updating status
  const updateStatus = async (status, denialMsg = "") => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}coperniq/updateScopeOfWorkStatus`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: id,
            status: status,
            denialMessage: denialMsg,
          }),
        }
      );
      if (res.ok) {
        return true;
      } else {
        setErrorMessage(`Error updating status to ${status}`);
        return false;
      }
    } catch (error) {
      console.error(`${status} error:`, error);
      setErrorMessage(`Error updating status to ${status}`);
      return false;
    }
  };

  const handleApprove = async () => {
    const success = await updateStatus("approved", "");
    if (success) {
      setData((prev) => ({ ...prev, status: "approved", denialMessage: "" }));
    }
  };

  const handleDeny = () => {
    setShowDenialInput(true);
  };

  const handleDenySubmit = async () => {
    const success = await updateStatus("denied", localDenialMessage);
    if (success) {
      setData((prev) => ({
        ...prev,
        status: "denied",
        denialMessage: localDenialMessage,
      }));
      setShowDenialInput(false);
    }
  };

  if (isLoading) {
    return (
      <StyledContainer>
        <Oval height="2rem" />
      </StyledContainer>
    );
  }

  // Define table sections with rows using mapping
  const tableSections = [
    {
      title: "Deal Details",
      rows: [
        { label: "Customer Name", value: data.customerName },
        { label: "Phone Number", value: data.phoneNumber },
        { label: "Email", value: data.email },
        { label: "Sales Rep", value: data.salesRep },
        { label: "Setter", value: data.setter },
        { label: "Lead Source", value: data.leadSource },
      ],
    },
    {
      title: "System Details",
      rows: [
        { label: "System Size", value: data.systemSize },
        { label: "Panel Type", value: data.panelType },
        { label: "Panel Count", value: data.panelCount },
        { label: "Inverter Type", value: data.inverterType },
        { label: "Inverter Count", value: data.inverterCount },
        { label: "Battery Type", value: data.batteryType },
        { label: "Battery Count", value: data.batteryCount },
      ],
    },
    {
      title: "Financing Details",
      rows: [
        { label: "Lender", value: data.lender },
        { label: "Term Length", value: data.termLength },
        { label: "Finance Type", value: data.financeType },
        { label: "Interest Rate / Escalator", value: data.interestRate },
        { label: "Total Contract Amount", value: data.totalContractAmount },
        { label: "Dealer Fee Amount", value: data.dealerFeeAmount },
      ],
    },
    {
      title: "Adder Details",
      rows: [
        { label: "Additional Wire Run ($)", value: data.additionalWireRun },
        { label: "Battery Adder ($)", value: data.batteryAdder },
        { label: "Battery Inside Garage ($)", value: data.batteryInsideGarage },
        { label: "Battery On Mobile Home ($)", value: data.batteryOnMobileHome },
        { label: "Concrete Coated ($)", value: data.concreteCoated },
        { label: "Detach and Reset ($)", value: data.detachAndReset },
        { label: "Ground Mount ($)", value: data.groundMount },
        { label: "High Roof ($)", value: data.highRoof },
        { label: "Inverter Adder ($)", value: data.inverterAdder },
        { label: "Level 2 Charger Install ($)", value: data.level2ChargerInstall },
        { label: "Lightreach Adder ($)", value: data.lightreachAdder },
        { label: "Metal Roof ($)", value: data.metalRoof },
        { label: "Meter Main ($)", value: data.meterMain },
        { label: "MPU ($)", value: data.mpu },
        { label: "Misc Electrical ($)", value: data.miscElectrical },
        { label: "Module Adder ($)", value: data.moduleAdder },
        { label: "Mounting Adder ($)", value: data.mountingAdder },
        { label: "New Roof ($)", value: data.newRoof },
        { label: "Project Fees ($)", value: data.projectFees },
        { label: "Span Smart Panel ($)", value: data.spanSmartPanel },
        { label: "Solar Insure ($)", value: data.solarInsure },
        { label: "Solar Insure With Battery ($)", value: data.solarInsureWithBattery },
        { label: "Steep Roof ($)", value: data.steepRoof },
        { label: "Structural Reinforcement ($)", value: data.structuralReinforcement },
        { label: "Tesla EV Charger ($)", value: data.teslaEvCharger },
        { label: "Tier 2 Insurance ($)", value: data.tier2Insurance },
        { label: "Tile Roof/Metal Roof/Metal Shingle ($)", value: data.tileRoofMetalRoofMetalShingle },
        { label: "Travel Adder ($)", value: data.travelAdder },
        { label: "Tree Trimming ($)", value: data.treeTrimming },
        { label: "Trench Over 100 Ft ($)", value: data.trenchOver100Ft },
        { label: "Wallbox Charger ($)", value: data.wallboxCharger },
        { label: "Subpanel 100A ($)", value: data.subpanel100a }
      ],
    },
    {
      title: "Commission Breakdown",
      rows: [
        { label: "Gross PPW", value: data.grossPPW },
        { label: "Total Adders (PPW)", value: data.totalAdders },
        { label: "Net PPW", value: data.netPPW },
        { label: "Total Commission", value: data.totalCommission },
      ],
    },
  ];

  return (
    <StyledContainer>
      {/* Logo and Header */}
      <div style={{ textAlign: "center" }}>
        <Logo src={img} alt={alt} />
        <Title>Scope of Work</Title>
        <Typography variant="h5" style={{ marginTop: "20px" }}>
          {data.title}
        </Typography>
      </div>

      {/* Render each section using MUI Table */}
      {tableSections.map((section) => {
        // For the Adder Details section, filter out rows with null or 0 values
        const rowsToRender =
          section.title === "Adder Details"
            ? section.rows.filter((row) => row.value !== null && row.value !== 0)
            : section.rows;

        // If it's the Adder Details section, compute the total
        const addersTotal =
        section.title === "Adder Details"
          ? rowsToRender.reduce((acc, row) => acc + Number(row.value), 0)
          : null;

        return (
          <div key={section.title} style={{ marginTop: "20px" }}>
            <Typography variant="h6">{section.title}</Typography>
            <Table size="small" style={{ marginTop: "10px" }}>
              <TableBody>
                {rowsToRender.map((row) => (
                  <TableRow key={row.label}>
                    <TableCell
                      style={
                        section.title === "Commission Breakdown"
                          ? getCommissionCellStyle(row.label)
                          : {}
                      }
                    >
                      {row.label}
                    </TableCell>
                    <TableCell
                      style={
                        section.title === "Commission Breakdown"
                          ? getCommissionCellStyle(row.label)
                          : {}
                      }
                    >
                      {row.value || "-"}
                    </TableCell>
                  </TableRow>
                ))}
                {section.title === "Adder Details" && (
                  <TableRow key="adders-total">
                    <TableCell>
                      <strong>Adders Total ($)</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{addersTotal}</strong>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        );
      })}

      {/* Proposal Section: Render as an image */}
      <div style={{ marginTop: "20px" }}>
        <Typography variant="h6">Proposal</Typography>
        {data.proposalUrl ? (
          <img
            src={data.proposalUrl}
            alt="Proposal"
            style={{
              maxWidth: "100%",
              height: "auto",
              marginTop: "10px",
              border: "1px solid #ccc",
            }}
          />
        ) : (
          <Typography variant="body2">No proposal available</Typography>
        )}
      </div>

      {/* Plan PDF Section */}
      <div style={{ marginTop: "20px" }}>
        <Typography variant="h6">Plan</Typography>
        {data.planUrl ? (
          <iframe
            src={data.planUrl}
            title="Plan PDF"
            style={{ width: "100%", height: "350px", border: "none" }}
          ></iframe>
        ) : (
          <Typography variant="body2">No plan available</Typography>
        )}
      </div>

      {/* Approval / Denial Section */}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        {data.status === "approved" ? (
          <Typography variant="h3" style={{ color: "green" }}>
            Approved
          </Typography>
        ) : data.status === "denied" ? (
          <>
            <Typography variant="h3" style={{ color: "red" }}>
              Denied
            </Typography>
            <TextField
              label="Denial Message"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={data.denialMessage}
              InputProps={{ readOnly: true }}
              style={{ marginTop: "20px" }}
            />
          </>
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleApprove}
              style={{ marginRight: "20px" }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleDeny}
            >
              Deny
            </Button>
            {showDenialInput && (
              <div style={{ marginTop: "20px" }}>
                <Typography variant="h6">Denial Message</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={localDenialMessage}
                  onChange={(e) => setLocalDenialMessage(e.target.value)}
                  style={{ marginTop: "10px" }}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleDenySubmit}
                  style={{ marginTop: "10px" }}
                >
                  Submit Denial
                </Button>
              </div>
            )}
            {errorMessage && (
              <Typography
                variant="body1"
                style={{ color: "red", marginTop: "20px" }}
              >
                {errorMessage}
              </Typography>
            )}
          </>
        )}
      </div>
    </StyledContainer>
  );
}

export default ScopeOfWork;
